import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { AxiosResponse } from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { PaymentTicket, TicketActionResponse } from '../core/_models'
import { KTIcon } from '../../../../_metronic/helpers'
import { convertUTCtoMSK, formatSum } from '../../../../utils'
import {
  finishTicketAPI,
  cancelTicketAPI,
  ticketFileGet,
  ticketAppendMerchantGet,
} from '../core/_requests'
import { ROLE, useAuth } from '../../auth'
import { ETicketStatus } from '../../../../types/enums'
import { openFile } from '../../../../utils/openFile'
import MerchantFilter from '../../../common/filter/MerchantFilter'
import { IsGranted } from '../../auth/PrivateRoute'

const AppSwal = withReactContent(Swal)

const ActiveTicketItem = (props: {
  ticket: PaymentTicket
  removeTicketFromList: (token: string) => void
  updateTicketList: () => void
  setShowAlert: Dispatch<SetStateAction<boolean>>
}) => {
  const amountInputRef = useRef<HTMLInputElement>(null)
  const [amount, setAmount] = useState('')
  const ticket = useState(Object.assign(props.ticket, {}))[0]
  const { currentUser } = useAuth()
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  useEffect(() => {}, [ticket])

  const cancelTicket = async () => {
    AppSwal.fire({
      title: 'Вы точно хотите отменить операцию?',
      showCancelButton: true,
      cancelButtonText: 'Нет',
      confirmButtonText: 'Да, отменить',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await cancelTicketAPI(ticket.token, currentUser)

          if (response.data && response.data.success) {
            handleCancelFinishResponse(response)
          } else {
            if (response.status === 400) {
              AppSwal.fire('', 'Невозможно отменить тикет', 'warning')
            }

            if (response.status === 422) {
              AppSwal.fire('', 'Тикет не может быть отменен', 'warning')
            }

            return false
          }
        } catch (err) {
          console.log('error catched: ', err)
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        AppSwal.fire({
          title: `Операция отменена успешно!`,
          timer: 1500,
        })
      }
    })
  }

  const finishTicket = async (externalUserId: string = '') => {
    let response = await finishTicketAPI(ticket.token, externalUserId, currentUser, amount)

    if (response.status === 400) {
      Swal.fire('', 'Невозможно завершить тикет', 'warning')
    }

    if (response.status === 422) {
      Swal.fire('', 'Тикет не может быть завершен', 'warning')
    }

    handleCancelFinishResponse(response)
  }

  const handleCancelFinishResponse = (response: AxiosResponse<TicketActionResponse>) => {
    if (!response.data) {
      return false
    }

    if (response.data.success && response.data.ticket) {
      if (response.data.ticket.status !== ETicketStatus.ACTIVE) {
        props.removeTicketFromList(ticket.token)
      }
    }
  }

  const onChangeMerchant = async (selected: any) => {
    await ticketAppendMerchantGet(ticket.token, selected.value[0])

    props.updateTicketList()
  }

  const onFileClick = async () => {
    const res = await ticketFileGet(ticket.bill)
    openFile(res.data)
  }

  const handleCopyToClipboard = (e: MouseEvent<HTMLSpanElement>) => {
    const span = e.target as HTMLElement
    navigator.clipboard.writeText(span.innerText)
    props.setShowAlert(true)
    setTimeout(() => {
      props.setShowAlert(false)
    }, 1400)
  }

  return (
    <>
      <tr>
        <td className='w-300px'>
          <p>{ticket.token}</p>
          <div className='d-flex align-items-center'>
            <span className='fw-bold fs-8'>Мерчант ID: </span>
            <p className='fs-9 ms-2'>{ticket?.transactionId}</p>
          </div>
        </td>
        <td>{convertUTCtoMSK(ticket.createdAt)}</td>
        {IsGranted(
          [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_CASHIER, ROLE.ROLE_SENIOR_CASHIER],
          currentUser
        ) && (
          <td>
            {ticket.amount ? (
              formatSum(ticket.amount)
            ) : (
              <input
                className={`border border-${
                  parseFloat(amount) <= 0 || amount === '' ? 'danger' : 'success'
                }`}
                ref={amountInputRef}
                type='number'
                min={0.1}
                required
                value={amount}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setAmount(e.target.value)}
                placeholder='Сумма обязательное поле'
                style={{ width: '100px', color: '#000000' }}
              />
            )}
          </td>
        )}

        <td className='max-w-220px position-relative'>
          {ticket?.lastExternalTransaction?.paymentAdapterType === 'EXTERNAL' ? (
            <>
              <span className='badge '>
                {ticket?.lastExternalTransaction?.paymentAdapterName || '-'}
              </span>
              <span className='separator' />
              {ticket?.lastExternalTransaction?.paymentId ? (
                <span
                  onMouseEnter={() => setShowTooltip((prev) => !prev)}
                  onMouseLeave={() => setShowTooltip((prev) => !prev)}
                  onClick={handleCopyToClipboard}
                  className='badge d-block text-truncate w-200px cursor-pointer text-start'
                >
                  {ticket?.lastExternalTransaction?.paymentId || '-'}
                </span>
              ) : (
                <span className='badge'>{'-'}</span>
              )}
              {showTooltip && (
                <div
                  style={{
                    width: 'fit-content',
                    zIndex: 20,
                  }}
                  className='badge position-absolute top-5 left-15px bg-success text-white'
                >
                  <p>{ticket?.lastExternalTransaction?.paymentId}</p>
                </div>
              )}
            </>
          ) : (
            <>
              <span className='badge'>{ticket.issuedRequisite?.name || '-'}</span>
              <span className='separator' />
              <span className='badge'>{ticket.issuedRequisite?.requisiteType || '-'}</span>
            </>
          )}
        </td>
        <td>{ticket.source.name}</td>
        <td>{ticket.sms ? ticket.sms.text : 'смс не поступило'}</td>
        {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
          <td>
            {ticket.merchant?.title || (
              <MerchantFilter changeSelect={onChangeMerchant} value={['']} />
            )}
          </td>
        )}
        <td className='text-center'>
          {ticket.bill ? (
            <span onClick={onFileClick} className='cursor-pointer'>
              <KTIcon className='fs-1' iconName='file' />
            </span>
          ) : (
            ''
          )}
        </td>
        <td className='text-end'>
          {IsGranted(
            [ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER, ROLE.ROLE_SENIOR_CASHIER, ROLE.ROLE_CASHIER],
            currentUser
          ) && (
            <>
              {(ticket.source.value === 'MERCHANT' && ticket.status === 'WAITING_FOR_REQUISITE') ||
              (ticket.source.value === 'SMS' && ticket.status === 'WAITING_FOR_MERCHANT') ? null : (
                <button
                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 me-4'
                  onClick={() => {
                    if (ticket.amount || parseFloat(amount) > 0) {
                      finishTicket()
                    } else {
                      amountInputRef?.current?.focus()
                    }
                  }}
                >
                  <KTIcon className='fs-1' iconName='check-circle' />
                </button>
              )}
              <button
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 fs-6'
                onClick={() => cancelTicket()}
              >
                <KTIcon className='fs-1' iconName='cross-circle' />
              </button>
            </>
          )}
        </td>
      </tr>
    </>
  )
}

export default ActiveTicketItem
